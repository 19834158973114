export const LINK_MESSAGE_TYPE = 'imx-link';
export const messageTypes = {
  inProgress: 'inProgress',
  success: 'success',
  fail: 'fail',
  result: 'result',
  close: 'close',
};

export type Message = {
  type: string;
};
