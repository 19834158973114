import * as t from 'io-ts';

import {
  ERC20TokenTypeT,
  ERC721TokenTypeT,
  EthAddress,
  ETHTokenTypeT,
  HexadecimalString,
  PositiveNumberStringC,
} from './runtime';

const FlatETHTokenCodec = t.interface({
  type: ETHTokenTypeT,
});

const FlatETHTokenWithAmountCodec = t.intersection([
  FlatETHTokenCodec,
  t.interface({ amount: PositiveNumberStringC }),
]);

const FlatERC721TokenCodec = t.interface({
  type: ERC721TokenTypeT,
  tokenId: t.string,
  tokenAddress: EthAddress,
});

export type FlatERC721Token = t.TypeOf<typeof FlatERC721TokenCodec>;

const FlatERC20TokenCodec = t.interface({
  type: ERC20TokenTypeT,
  tokenAddress: EthAddress,
  symbol: t.string,
});

export type FlatERC20Token = t.TypeOf<typeof FlatERC20TokenCodec>;

const FlatERC20TokenWithAmountCodec = t.intersection([
  FlatERC20TokenCodec,
  t.interface({ amount: PositiveNumberStringC }),
]);

export const FlatTokenCodec = t.union([
  FlatETHTokenCodec,
  FlatERC721TokenCodec,
  FlatERC20TokenCodec,
]);
export type FlatToken = t.TypeOf<typeof FlatTokenCodec>;
export type FlatTokenTS = t.OutputOf<typeof FlatTokenCodec>;

export const FlatTokenWithAmountCodec = t.union([
  FlatETHTokenWithAmountCodec,
  FlatERC721TokenCodec,
  FlatERC20TokenWithAmountCodec,
]);
export type FlatTokenWithAmount = t.TypeOf<typeof FlatTokenWithAmountCodec>;
export type FlatTokenWithAmountTS = t.OutputOf<typeof FlatTokenWithAmountCodec>;

const TransferParamsCodec = t.intersection([
  FlatTokenWithAmountCodec,
  t.interface({
    to: EthAddress,
  }),
]);

export const FlatTokenWithAmountAndToAddressCodec = t.intersection([
  FlatTokenWithAmountCodec,
  t.type({
    toAddress: EthAddress,
  }),
]);
export type FlatTokenWithAmountAndToAddress = t.TypeOf<
  typeof FlatTokenWithAmountAndToAddressCodec
>;
export type FlatTokenWithAmountAndToAddressTS = t.OutputOf<
  typeof FlatTokenWithAmountAndToAddressCodec
>;

const TransferV2ParamsCodec = t.array(FlatTokenWithAmountAndToAddressCodec);
export type TransferV2ParamsCodecTS = t.OutputOf<typeof TransferV2ParamsCodec>;

const BuyParamsCodec = t.interface({
  orderId: PositiveNumberStringC,
});

const BuyV2ParamsCodec = t.interface({
  orderIds: t.array(PositiveNumberStringC),
});

const SellParamsCodec = t.intersection([
  t.type({
    tokenId: t.string,
    tokenAddress: EthAddress,
  }),
  t.partial({
    amount: PositiveNumberStringC,
    currencyAddress: EthAddress,
  }),
]);

const CancelParamsCodec = t.interface({
  orderId: PositiveNumberStringC,
});

export enum ProviderPreference {
  METAMASK = 'metamask',
  MAGIC_LINK = 'magic_link',
  NONE = 'none',
}

const SetupParamsCodec = t.partial({
  providerPreference: t.union([
    t.literal(ProviderPreference.METAMASK),
    t.literal(ProviderPreference.MAGIC_LINK),
    t.literal(ProviderPreference.NONE),
  ]),
});

export namespace LinkParamsCodecs {
  export const Setup = SetupParamsCodec;
  export const History = t.interface({});
  export const Buy = BuyParamsCodec;
  export const BuyV2 = BuyV2ParamsCodec;
  export const CompleteWithdrawal = FlatTokenCodec;
  export const Deposit = FlatTokenWithAmountCodec;
  export const PrepareWithdrawal = FlatTokenWithAmountCodec;
  export const Sell = SellParamsCodec;
  export const Transfer = TransferParamsCodec;
  export const TransferV2 = TransferV2ParamsCodec;
  export const Cancel = CancelParamsCodec;
  export const Claim = t.interface({});
  export const Exchange = t.interface({});
}

export namespace LinkParamsF {
  export type Setup = t.TypeOf<typeof LinkParamsCodecs.Setup>;
  export type History = t.TypeOf<typeof LinkParamsCodecs.History>;
  export type Buy = t.TypeOf<typeof LinkParamsCodecs.Buy>;
  export type BuyV2 = t.TypeOf<typeof LinkParamsCodecs.BuyV2>;
  export type CompleteWithdrawal = t.TypeOf<
    typeof LinkParamsCodecs.CompleteWithdrawal
  >;
  export type Deposit = t.TypeOf<typeof LinkParamsCodecs.Deposit>;
  export type PrepareWithdrawal = t.TypeOf<
    typeof LinkParamsCodecs.PrepareWithdrawal
  >;
  export type Sell = t.TypeOf<typeof LinkParamsCodecs.Sell>;
  export type Transfer = t.TypeOf<typeof LinkParamsCodecs.Transfer>;
  export type TransferV2 = t.TypeOf<typeof LinkParamsCodecs.TransferV2>;
  export type Cancel = t.TypeOf<typeof LinkParamsCodecs.Cancel>;
  export type Claim = t.TypeOf<typeof LinkParamsCodecs.Claim>;
  export type Exchange = t.TypeOf<typeof LinkParamsCodecs.Exchange>;
}

export namespace LinkParams {
  export type Setup = t.OutputOf<typeof LinkParamsCodecs.Setup>;
  export type History = t.OutputOf<typeof LinkParamsCodecs.History>;
  export type Buy = t.OutputOf<typeof LinkParamsCodecs.Buy>;
  export type BuyV2 = t.OutputOf<typeof LinkParamsCodecs.BuyV2>;
  export type CompleteWithdrawal = t.OutputOf<
    typeof LinkParamsCodecs.CompleteWithdrawal
  >;
  export type Deposit = t.OutputOf<typeof LinkParamsCodecs.Deposit>;
  export type PrepareWithdrawal = t.OutputOf<
    typeof LinkParamsCodecs.PrepareWithdrawal
  >;
  export type Sell = t.OutputOf<typeof LinkParamsCodecs.Sell>;
  export type Transfer = t.OutputOf<typeof LinkParamsCodecs.Transfer>;
  export type TransferV2 = t.OutputOf<typeof LinkParamsCodecs.TransferV2>;
  export type Cancel = t.OutputOf<typeof LinkParamsCodecs.Cancel>;
  export type Claim = t.OutputOf<typeof LinkParamsCodecs.Claim>;
  export type Exchange = t.OutputOf<typeof LinkParamsCodecs.Exchange>;
}

const SetupResultsCodec = t.interface({
  address: EthAddress,
  starkPublicKey: HexadecimalString,
  ethNetwork: t.string,
  providerPreference: t.string,
});

const SuccessCodec = t.literal('success');
const ErrorCodec = t.literal('error');

const BuyV2ResultsCodec = t.interface({
  result: t.record(
    PositiveNumberStringC,
    t.union([
      t.type({ status: SuccessCodec }),
      t.type({ status: ErrorCodec, message: t.string }),
    ]),
  ),
});

const TransferV2TokenWithResult = t.intersection([
  FlatTokenWithAmountAndToAddressCodec,
  t.union([
    t.type({ status: SuccessCodec, txId: t.Int }),
    t.type({ status: ErrorCodec, message: t.string }),
  ]),
]);

const TransferV2ResultsCodec = t.interface({
  result: t.array(TransferV2TokenWithResult),
});

const PrepareWithdrawalCodec = t.interface({
  withdrawalId: t.Int,
});

const CompleteWithdrawalCodec = t.interface({
  transactionId: t.string,
});

export namespace LinkResultsCodecs {
  export const Setup = SetupResultsCodec;
  export const BuyV2 = BuyV2ResultsCodec;
  export const TransferV2 = TransferV2ResultsCodec;
  export const PrepareWithdrawal = PrepareWithdrawalCodec;
  export const CompleteWithdrawal = CompleteWithdrawalCodec;
}

export namespace LinkResultsF {
  export type Setup = t.TypeOf<typeof LinkResultsCodecs.Setup>;
  export type BuyV2 = t.TypeOf<typeof LinkResultsCodecs.BuyV2>;
  export type TransferV2 = t.TypeOf<typeof LinkResultsCodecs.TransferV2>;
  export type PrepareWithdrawal = t.TypeOf<
    typeof LinkResultsCodecs.PrepareWithdrawal
  >;
  export type CompleteWithdrawal = t.TypeOf<
    typeof LinkResultsCodecs.CompleteWithdrawal
  >;
}

export namespace LinkResults {
  export type Setup = t.OutputOf<typeof LinkResultsCodecs.Setup>;
  export type BuyV2 = t.OutputOf<typeof LinkResultsCodecs.BuyV2>;
  export type TransferV2 = t.OutputOf<typeof LinkResultsCodecs.TransferV2>;
  export type PrepareWithdrawal = t.OutputOf<
    typeof LinkResultsCodecs.PrepareWithdrawal
  >;
  export type CompleteWithdrawal = t.OutputOf<
    typeof LinkResultsCodecs.CompleteWithdrawal
  >;
}
