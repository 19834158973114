export enum Routes {
  'Home' = 'home',
  'Setup' = 'setup',
  'Buy' = 'buy',
  'BuyV2' = 'v2/buy',
  'Sell' = 'sell',
  'Deposit' = 'deposit',
  'History' = 'history',
  'PrepareWithdrawal' = 'prepare-withdrawal',
  'CompleteWithdrawal' = 'complete-withdrawal',
  'Transfer' = 'transfer',
  'TransferV2' = 'v2/transfer',
  'Cancel' = 'cancel',
  'Claim' = 'claim',
  'ClaimUSInstant' = 'claim-instant',
  'ClaimUSOverTime' = 'claim-over-time',
  'ClaimUSOverTimeDetails' = 'claim-over-time-details',
  'Exchange' = 'exchange',
}
